import React from "react";
import { NavLink } from "react-router-dom";
import style from './Button.module.css';


const Button = ({ text, title, children }) => {
    return (
        <div className={style.btn}>
            {children}
            <NavLink to={title.toLocaleLowerCase()} className="">
                {text}
            </NavLink>
        </div>
    )
}

export default Button;