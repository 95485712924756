import React, {useState} from "react";
import style from '../Page.module.css';
import parseKalinka from '../../../../../parsers/kalinka-realty/kalinka_parse';
import {useDispatch} from "react-redux";
import {
    addObject,
    toggleIsFetching,
    changeCheckboxChecked,
    changeParseSchedule, addCatKey
} from "../../../../../redux/kalinkaSlice";
import styleDB from '../Database/Database.module.css';
import Preloader from "../../../Preloader/Preloader";
import {useSelector} from 'react-redux';
import {dbAPI} from '../../../../../api/api';
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {setResult} from "../../../../../utils/common";

const Parsing = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [reqError, setreqError] = useState('');

    const isFetching = useSelector(state => state.kalinka.isFetching);
    const objectsCollected = useSelector(state => state.kalinka.objectsCollected);
    const filters = useSelector(state => state.kalinka.filters);

    const [hour, setHour] = useState('');
    const [days, setDays] = useState('');

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            dispatch(toggleIsFetching(true));
            const parseResult = await parseKalinka(value);
            dispatch(addObject(await dbAPI.uploadObjects({
                    data: parseResult.data,
                    types: Object.keys(filters).filter(key => filters[key]).join(','),
                    catKey: parseResult.catKey,
                    url: value,
                    parseDays: +days || 1,
                    parseHours: +hour
                }
            )));
            dispatch(addCatKey(parseResult.catKey));
            dispatch(toggleIsFetching(false));
            setreqError('');
        } catch (err) {
            setreqError(err.message);
            dispatch(toggleIsFetching(false));
        }
        setValue('')
    }
    const checkboxClickHandler = (e) => {
        dispatch(changeCheckboxChecked({key: e.target.id, checked: e.target.checked}));
    }
    return (
        <div className="border flex h-full flex-col text-gray-800">
            <div className={style.block + " w-[100%] bg-[#18a4e1] mb-[15px] "}>
                <div className={style.header}>
                    <h3 className="text-left text-xl font-mono text-gray-800">Парсинг</h3>
                </div>
            </div>
            <div className="flex h-[100%] gap-4 ">
                <div className={style.block + ' ' + styleDB.itemsBlock}>
                    <div className="h-full flex flex-col">
                        <form onSubmit={submitHandler} className={style.filtersForm + ' flex-auto'}>
                            <div className="flex-auto">
                                <div className={style.itemBlock}>
                                    <span className={style.itemCaption}>URL:</span>
                                    <input id="url" className={style.itemInput} required type="text"
                                           placeholder="Введите URL страници, которую хотите спарсить" value={value}
                                           onChange={e => setValue(e.target.value)}/>
                                </div>
                                <div className={style.itemBlock + ' justify-center items-center'}>
                                    <div className={style.checkboxBlock}>
                                        <div className={style.checkboxRect}>
                                            <input type="checkbox" id="all_obj" name="check"
                                                   onChange={checkboxClickHandler} checked={filters.all_obj}/>
                                            <label htmlFor="all_obj">Все параметры</label>
                                        </div>
                                        <div className={style.checkboxRect}>
                                            <input type="checkbox" id="price" name="check"
                                                   onChange={checkboxClickHandler} checked={filters.price}/>
                                            <label htmlFor="price">Цена</label>
                                        </div>
                                        <div className={style.checkboxRect}>
                                            <input type="checkbox" id="newObjects" name="check"
                                                   onChange={checkboxClickHandler} checked={filters.newObjects}/>
                                            <label htmlFor="newObjects">Новые объекты</label>
                                        </div>
                                        {/* </div>
                                        <div> */}
                                        <div className={style.checkboxRect}>
                                            <input type="checkbox" id="deletedObjects" name="check"
                                                   onChange={checkboxClickHandler} checked={filters.deletedObjects}/>
                                            <label htmlFor="deletedObjects">Удаленные объекты</label>
                                        </div>
                                        <div className={style.checkboxRect}>
                                            <input type="checkbox" id="finishing" name="check"
                                                   onChange={checkboxClickHandler} checked={filters.finishing}/>
                                            <label htmlFor="finishing">Отделка</label>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Часы | Минуты</InputGroup.Text>
                                    <Form.Control placeholder={"Часы (0 - 23)"}
                                                  type="number"
                                                  min="0" max="23" step="1"
                                                  value={hour}
                                                  onChange={(e) => {
                                                      const value = e.target.value > 23 ? 23 : e.target.value < 0 ? 0 : e.target.value;
                                                      setHour(value);
                                                  }}
                                                  aria-label="Часы"/>
                                    <Form.Control placeholder={"Дни (Каждые N дней)"}
                                                  type="number"
                                                  min="1" max="100" step="1"
                                                  value={days}
                                                  onChange={(e) => {
                                                      const value = e.target.value < 1 ? 1 : e.target.value;
                                                      setDays(value);
                                                  }}
                                                  aria-label="Дни"/>
                                </InputGroup>
                            </div>
                            <div>
                                <div className="w-[100%] flex justify-center items-center mb-5 text-[18px]">
                                    {isFetching ? <Preloader/> : ''}
                                </div>

                                {
                                    reqError
                                        ? <div
                                            className="w-[100%] text-center text-[red] mb-5 text-[18px]">{reqError}</div>
                                        : <div
                                            className="w-[100%] text-center text-[green] mb-5 text-[18px]">{objectsCollected ? `Успешно собрано ${objectsCollected} объктов !` : 'Список объектов пуст'}</div>

                                }

                                <div className={style.itemBlock + ' w-[100%]'}>
                                    <button disabled={isFetching} className={style.startBtn}>Запустить</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>

        </div>

    )
}
export default Parsing;