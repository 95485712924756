import './App.css';
import Layout from './components/ui/layout/Layout';
import Page from './components/ui/layout/Page/Page';
import Sidebar from './components/ui/layout/Sidebar/Sidebar';
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Layout>
      <Sidebar />
      <Page />
    </Layout>
  );
}

export default App;

