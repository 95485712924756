import React from "react";
import { Route, Routes } from "react-router-dom";
import Parsing from "./Parsing/Parsing";
import Settings from "./Settings/Settings";
import Database from "./Database/Database";
import Login from "../../Login/Login";


const Page = () => {
    return (
        <div className="flex-auto border rounded-3xl bg-zinc-200 p-7">
            <Routes>
                <Route path='/parsing' element={<Parsing/>}/>
                <Route path='/database' element={<Database />} />
                <Route path='/settings' element={<Settings />} />
            </Routes>
        </div>

    )
}
export default Page;