import React from "react";
import loader from '../../../assets/img/common/loader.gif';

const Preloader = ({width, height}) => {
    return (
        <div width={width} height={height}>
            <img src={loader} alt="loader" />
        </div>
    )
}
export default Preloader;