import { kalinkaAPI } from '../../api/api';
import { getDataFromStr, replaceCharForRegExp, getKeyFromUrl, sleep } from '../../utils/common';
import cheerio from 'cheerio';

export default async function parseKalinka(url) {
    return new Promise(async (resolve, reject) => {
        const urlSearchParams = new URLSearchParams(url);
        const params = Object.fromEntries(urlSearchParams.entries());
        let queryParams = '?';
        for (let key in params) {
            if (key === url) { queryParams = '?count=100&'; continue; }
            const value = params[key];
            let tempkey = key;
            if (key.split('?').length > 1) {
                tempkey = key.split('?')[1];
            }
            queryParams += `${tempkey}=${tempkey === 'count' ? 100 : value}&`;
        }
        try { 
            return resolve({
                data: await runKalinkaParse(url.split('?')[0] + `${queryParams}`), 
                catKey: getKeyFromUrl(url)
            });
        } catch (err) {
            return reject(err);
        }
    })


}



export async function runKalinkaParse(url, page=1) {
    return new Promise(async (resolve, reject) => {
        try{
            const kalinkaStorage = await getKalinkaStorage(url + `page=${page}`);
            return resolve(extractObjectInfo(kalinkaStorage, url));
        } catch (err) {
            return reject(err);
        }
    }) 
    
    
}


const getKalinkaStorage = (url) => {
    const varName = 'window.__NUXT__=';
    return new Promise((resolve, reject) => {
        kalinkaAPI.getPage(url)
            .then(data => {
                const $ = cheerio.load(data);
                const object_items = $('.object-list__item');
                const result = getDataFromStr(data, replaceCharForRegExp(varName), varName.length);
                if(object_items.length > 0) {
                    const images = {}
                    object_items.each((i, el) => {
                        images[$(el).find('p.offer-card__lot').text().replace(/[^0-9]/g, "")] = $(el).find('.offer-card__slide img').attr('data-src');
                    })
                    // console.log(images);
                    return resolve({result: eval(result), images});
                }else{
                    return resolve({result: eval(result), image: $('.object-gallery__picture img').attr('data-src')});
                }
                
                
            })
            .catch(async err => {
                await sleep(1000);
                console.log(err.message)
                let splitedUrl = url.split('page=');
                console.log('Retry GET: ', splitedUrl[0], splitedUrl[1]);
                // return resolve(await runKalinkaParse(splitedUrl[0], +splitedUrl[1], true));
                return resolve(await getKalinkaStorage(url));
                // reject(err)
            });
    })

}
const extractObjectInfo = async (kalinkaStorage, url) => {
    const stateOfStorage = kalinkaStorage.result.state;
    const isObject = 'object' in stateOfStorage && stateOfStorage.object.item != null;
    const objectKeys = ['description', 'salePriceRub', 'salePriceEur', 'salePriceUsd', 'rooms', 'address', 'area', 'bathrooms', 'bedrooms', 'areaLand', 'cabinet', 'title', 'lotid', 'url'];
    const resultData = [];
    if (isObject) {
        const item = stateOfStorage.object.item;
        const infosFromObj = getInfoFromObject(item, objectKeys);
        if(!('previewImage' in infosFromObj))
            infosFromObj.previewImage = kalinkaStorage.image;
        resultData.push(infosFromObj);
    } else {
        const catalog = stateOfStorage.catalogFilter;
        const pagination = catalog.pagination;
        console.log(pagination);
        const listObject = catalog.list;
        listObject.forEach(object => resultData.push(getInfoFromObject(object, objectKeys)));
        resultData.forEach(object => {
            if(!object.previewImage)
            object.previewImage = kalinkaStorage.images[object.lotid];
        });
        if (pagination.page < pagination.pageCount)
            resultData.push(...await runKalinkaParse(url, pagination.page + 1));
    }
    return resultData;
}
const getInfoFromObject = (object, info = []) => {
    const resultData = {};
    try {
        info.forEach((item, i) => {
            if (item in object) resultData[item] = object[item];
        });
        resultData.url = 'https://www.kalinka-realty.ru' + resultData.url;
        const imageLinks = object['imageLinks'];
        if (imageLinks)
            for (let i = 0; i < imageLinks.length; i++) {
                if (imageLinks[i].type === 'i') {
                    resultData['previewImage'] = imageLinks[i].pathPreview;
                    break;
                }
            }
        const finishing = object['finishing'];
        if(finishing)
            resultData['finishing'] = finishing.title;
    } catch(err) {
        console.log(err)
    }
    
    return resultData;
}

