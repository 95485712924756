import React from "react";
import {useState} from "react";
import style from '../Page.module.css';
import styleDB from '../Database/Database.module.css';
import {useDispatch, useSelector} from "react-redux";
import cronstrue from "cronstrue";
import {dbAPI} from "../../../../../api/api";
import Users from "./Users";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import {genUUID, setResult} from "../../../../../utils/common";
import {Alert} from "react-bootstrap";
import {changeParseSchedule, changeTelegramToken, changeUserPass} from "../../../../../redux/kalinkaSlice";

const Settings = () => {
    const dispatch = useDispatch();
    const role = useSelector(state => state.kalinka.role);
    const parseSchedule = useSelector(state => state.kalinka.parseSchedule);
    const [botToken, setBotToken] = useState('');
    const [chatId, setChatId] = useState('');
    const [fetchingToken, setFetchingToken] = useState(false);
    const [errorToken, setErrorToken] = useState('');
    const [successToken, setSuccessToken] = useState('');

    // const [hour, setHour] = useState(parseSchedule[1]);
    // const [minutes, setMinutes] = useState(parseSchedule[0]);
    // const [fetchingTime, setFetchingTime] = useState(false);
    // const [errorTime, setErrorTime] = useState('');
    // const [successTime, setSuccessTime] = useState('');

    const shortPass = useSelector(state => state.kalinka.shortPass);
    const [oldPass, setOldPass] = useState(shortPass || '');
    const [newPass, setNewPass] = useState('');
    const [fetchingPass, setFetchingPass] = useState(false);
    const [errorPass, setErrorPass] = useState('');
    const [successPass, setSuccessPass] = useState('');
    const tg = useSelector(state => state.kalinka.telegram);

    //const readable = cronstrue.toString(e.target.value);
    const onClickTokenHandler = async () => {
        if (botToken && chatId) {
            setFetchingToken(true);
            setSuccessToken('');
            setErrorToken('');
            try {
                const tgResult = await dbAPI.checkTgToken(botToken);
                if (tgResult.ok) {
                    const res = await dbAPI.changeTgToken(botToken, chatId);
                    if (res['success']) {
                        setResult(setSuccessToken, res['data']['message']);
                        dispatch(changeTelegramToken(res['data']));

                    } else {
                        setResult(setErrorToken, res['message']);
                    }
                    setFetchingToken(false);
                } else {
                    setResult(setErrorToken, 'Телеграм-БОТ ТОКЕН не действителен!');
                    setFetchingToken(false);
                }
            } catch (e) {
                if (e?.response?.data?.ok === false) {
                    setResult(setErrorToken, 'Телеграм-БОТ ТОКЕН не действителен!');
                } else {
                    setResult(setErrorToken, e.message || e);
                }
                setFetchingToken(false);

            }

        } else {
            setResult(setErrorToken, 'Поле BOT TOKEN и CHAT ID должно быть заполнено!');
        }
    }

    // const onClickTimeHandler = async () => {
    //     const schedule = `${minutes || 0} ${hour || 0} * * *`;
    //     try {
    //         setFetchingTime(true);
    //         setSuccessTime('');
    //         setErrorTime('');
    //         const res = await dbAPI.setScheduleCheck(schedule);
    //         if (res['success']) {
    //             dispatch(changeParseSchedule(res['data']['schedule']));
    //             setResult(setSuccessTime, res['data']['message']);
    //
    //         } else {
    //             setResult(setErrorTime, res['message']);
    //         }
    //         setFetchingTime(false);
    //     } catch (e) {
    //         setFetchingTime(false);
    //         setResult(setErrorTime, e.message || e);
    //     }
    // }
    const onClickPassHandler = async () => {
        try {
            setFetchingPass(true);
            setSuccessPass('');
            setErrorPass('');
            const res = await dbAPI.changeUserPass(oldPass, newPass);
            if (res['success']) {
                dispatch(changeUserPass(res['data']));
                setResult(setSuccessPass, res['data']['message']);

            } else {
                setResult(setErrorPass, res['message']);
            }
            setFetchingPass(false);
        } catch (e) {
            setFetchingPass(false);
            setResult(setErrorPass, e.message || e);
        }
    }
    return (
        <div className="border flex h-full flex-col text-gray-800">
            <div className={style.block + " w-[100%] bg-[#18a4e1] mb-[15px] "}>
                <div className={style.header}>
                    <h3 className="text-left text-xl font-mono text-gray-800">Настройки</h3>
                </div>
            </div>
            <div className="flex h-[90%] gap-4 ">
                <div className={style.block + ' ' + styleDB.itemsBlock + ' overflow-auto'}>
                    <div className="h-full flex flex-col">
                        {role === "admin" &&
                            <Users/>
                        }
                        {
                            [errorToken, errorPass].filter(error => error).map( error =>
                                <Alert key={genUUID()} variant="danger" className="text-lg">
                                {error}
                            </Alert>)
                        }
                        {
                            [successToken, successPass].filter(success => success).map(success =>
                                <Alert key={genUUID()} variant="success" className="text-lg">
                                {success}
                            </Alert>)
                        }
                        <InputGroup className="mb-3">
                            <InputGroup.Text>BOT TOKEN | CHAT ID</InputGroup.Text>
                            <Form.Control aria-label="BOT TOKEN" placeholder={tg[0] || ''} value={botToken}
                                          onChange={(e) => setBotToken(e.target.value)}/>
                            <Form.Control aria-label="CHAT ID" placeholder={tg[1] || ''}value={chatId}
                                          onChange={(e) => setChatId(e.target.value)}/>
                            <Button disabled={fetchingToken} onClick={onClickTokenHandler}>{
                                fetchingToken ? <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : "Изменить"
                            }
                            </Button>
                        </InputGroup>

                        {/*<InputGroup className="mb-3">*/}
                        {/*    <InputGroup.Text>Часы | Минуты</InputGroup.Text>*/}
                        {/*    <Form.Control placeholder={"Часы (0 - 23)"}*/}
                        {/*                  type="number"*/}
                        {/*                  min="0" max="23" step="1"*/}
                        {/*                  value={hour}*/}
                        {/*                  onChange={(e) => {*/}
                        {/*                      const value = e.target.value > 23 ? 23 : e.target.value < 0 ? 0 : e.target.value;*/}
                        {/*                      setHour(value);*/}
                        {/*                  }}*/}
                        {/*                  aria-label="часы"/>*/}
                        {/*    <Form.Control placeholder={"Минуты (0 - 59)"}*/}
                        {/*                  type="number"*/}
                        {/*                  min="0" max="59" step="1"*/}
                        {/*                  value={minutes}*/}
                        {/*                  onChange={(e) => {*/}
                        {/*                      const value = e.target.value > 59 ? 59 : e.target.value < 0 ? 0 : e.target.value;*/}
                        {/*                      setMinutes(value);*/}
                        {/*                  }}*/}
                        {/*                  aria-label="минуты"/>*/}
                        {/*    <Button disabled={fetchingTime} onClick={onClickTimeHandler}>*/}
                        {/*        {fetchingTime ? <Spinner*/}
                        {/*                as="span"*/}
                        {/*                animation="border"*/}
                        {/*                size="sm"*/}
                        {/*                role="status"*/}
                        {/*                aria-hidden="true"*/}
                        {/*            />*/}
                        {/*            : "Изменить"}*/}
                        {/*    </Button>*/}
                        {/*</InputGroup>*/}

                        <InputGroup id="changePassword" className="mb-3">
                            <Form.Control placeholder={"Старый пароль"}
                                          type="password"
                                          aria-label="Старый пароль"
                                          value={oldPass}
                                          onChange={(e) => setOldPass(e.target.value)}
                            />
                            <Form.Control placeholder={"Новый пароль"}
                                          type="password"
                                          aria-label="Новый пароль"
                                          value={newPass}
                                          onChange={(e) => setNewPass(e.target.value)}
                            />
                            <Button disabled={fetchingPass} onClick={onClickPassHandler}>
                                {fetchingPass ? <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : "Изменить"}
                            </Button>
                        </InputGroup>
                    </div>

                </div>
            </div>

        </div>

    )
}
export default Settings;