import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Alert} from "react-bootstrap";
import {dbAPI} from "../../../api/api";
import {Navigate} from "react-router-dom";
import {setLoadSite, toggleIsFetchingLogin} from "../../../redux/kalinkaSlice";
import Spinner from "react-bootstrap/Spinner";


const Login = () => {
    const dispatch = useDispatch();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const isAuthorized = useSelector(state => state.kalinka.isAuthorized);
    const isFetching = useSelector(state => state.kalinka.isFetchingLogin);
    const [error, setError] = useState('');

    const submitHandler = async (e) => {
        e.preventDefault();
        setError('');
        dispatch(toggleIsFetchingLogin(true));
        try{
            const {success, message} = await dbAPI.login(login, password);
            if(success) {
                dispatch(setLoadSite(await dbAPI.loadSite()));
            }else {
                setError(message);
            }
        } catch (e) {
            setError(e.message);
        } finally {
            dispatch(toggleIsFetchingLogin(false));
        }
    }

    return (
        <>
            {
                isAuthorized
                ? <Navigate to="/database"/>
                : <div className={"h-screen w-[100%] flex justify-center items-center"}>
                    <div className="w-[40%] h-[50%] border rounded-2xl p-6">
                        <form className="flex flex-col items-center h-full" onSubmit={submitHandler}>
                            <div className="flex-auto w-[100%]">
                                <Alert key="primary" variant="primary" className="text-lg">
                                    Авторизируйтесь для сбора и просмотра объектов...
                                </Alert>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Username"
                                    className="mb-3"
                                >
                                    <Form.Control type="login" placeholder="Realtor" required value={login}
                                                  onChange={e => setLogin(e.target.value)}/>
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingPassword" label="Password">
                                    <Form.Control type="password" placeholder="Password" required value={password}
                                                  onChange={e => setPassword(e.target.value)}/>
                                </FloatingLabel>
                            </div>
                            <div className="flex flex-col gap-3 w-full items-center max-h-[100%]">
                                {
                                    error &&
                                    <Alert key="danger" variant="danger" className="text-lg">
                                        {error}
                                    </Alert>
                                }
                                <Button type="submit" disabled={isFetching} className="mb-2 mt-5 w-1/3">
                                    {isFetching ? <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        : "Войти"}
                                </Button>
                            </div>
                        </form>


                    </div>
                </div>
            }
        </>


    )
}
export default Login;