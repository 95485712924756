
import { v4 as uuidv4 } from 'uuid';
export const genUUID = () => uuidv4();

export function getDataFromStr(target, variable, varLength = variable.length, end = "));") {
    var chopFront = target.substring(target.search(variable) + varLength, target.length);
    var result = chopFront.substring(0, chopFront.search(replaceCharForRegExp(end)) + end.length);
    return result;
}

export const replaceCharForRegExp = string => string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export function getKeyFromUrl(url) {
    let urlCatKeys = url.split('https://www.kalinka-realty.ru/')[1].split('?')[0];
    if(urlCatKeys.length === 0){
        urlCatKeys = url
    }
    return urlCatKeys;
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const setResult = (callback, data) => {
    callback(data)
    setTimeout(() => callback(''), 5000);
}
