import React, {useEffect, useRef, useState} from "react";
import Table from 'react-bootstrap/Table';
import {useDispatch, useSelector} from "react-redux";
import {dbAPI} from "../../../../../api/api";
import {addRegUser, changePassword, setUsers} from "../../../../../redux/kalinkaSlice";
import {Alert, Dropdown, DropdownButton} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import {genUUID, setResult} from "../../../../../utils/common";

const Users = () => {
    const users = useSelector(state => state.kalinka.users);
    const dispatch = useDispatch();
    const [errorFetching, setErrorFetching] = useState('');
    const [activeChngPass, setActiveChngPass] = useState('');
    const [changeError, setChangeError] = useState('');
    const [changeSuccess, setChangeSuccess] = useState('');
    const [isCngFetching, setIsCngFetching] = useState(false);

    const [newUserRole, setNewUserRole] = useState('realtor');
    const [newUserUsername, setNewUserUsername] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [isFetchingReg, setIsFetchingReg] = useState(false);
    const [registerError, setRegisterError] = useState('');
    const [registerSuccess, setRegisterSuccess] = useState('');

    const inputRef = useRef(null);

    useEffect(() => {
        (async function getUsers() {
            try{
                setErrorFetching('');
                dispatch(setUsers((await dbAPI.getUsers()).data));
            } catch (e) {
                setResult(setErrorFetching, e);
            }
        })();
    }, []);

    const ClickPassHandler = (e) => {
        if(!e.target.parentElement.classList.contains('change-pass'))
            setActiveChngPass('');
        if('userPass' in e.target.dataset) {
            setActiveChngPass(e.target.dataset.userPass);
        }
    }

    const onChangePassClick = async (e) => {
        if(activeChngPass) {
            setChangeSuccess('');
            setChangeError('');
            setIsCngFetching(true);
            dbAPI.changePass(activeChngPass, inputRef.current.value)
                .then(result => {
                    if(result.success) {
                        setResult(setChangeSuccess, result.data.message);
                        setChangeSuccess(result.data.message);
                        dispatch(changePassword(result.data));
                        setActiveChngPass('');
                        setIsCngFetching(false);
                    }else {
                        if(Array.isArray(result['message'])) {
                            const invalidPasswordMessage = result['message'].reduce((msgs, msg ) => {
                                if(msg?.validation === 'uppercase')
                                    return msgs + 'Пароль должен содержать минимум одну заглавную букву! \n';
                                if(msg?.validation === 'min')
                                    return msgs + `Пароль должен содержать минимум ${msg['arguments'] || 0 } символов! \n`
                                if(msg?.validation === 'spaces')
                                    return msgs + 'Пароль должен быть без пробелов!';
                                return msgs;
                            }, '');
                            setResult(setChangeError, invalidPasswordMessage);
                            setIsCngFetching(false);
                        } else {
                            setResult(setChangeError, result['message']);
                            setIsCngFetching(false);
                        }
                    }

                })
                .catch(err => {
                    setResult(setChangeError, err.message || err);
                    setIsCngFetching(false);
                });
        }
    }
    const handlerChangeRole = e => {
        setNewUserRole(e.target.value);
    }
    const handlerRegisterUser = async () => {
        try{
            setRegisterSuccess('');
            setRegisterError('');
            setIsFetchingReg(true);
            const res = await dbAPI.register(newUserUsername, newUserPassword, newUserRole);
            if(res['success']) {
                dispatch(addRegUser(res['data']));
                setResult(setRegisterSuccess, res['data']['message']);

            }else {
                const validateMsgs = res?.['message']?.['validUsername'] || res?.['message']?.['validPass'];
                const field = res?.['message']?.['validUsername'] ? 'Username' : res?.['message']?.['validPass'] ? 'Пароль' : '';
                if(Array.isArray(validateMsgs)) {
                    const invalidPasswordMessage = validateMsgs.reduce((msgs, msg ) => {
                        if(msg?.validation === 'uppercase')
                            return msgs + `${field} должен содержать минимум одну заглавную букву! \n`;
                        if(msg?.validation === 'min')
                            return msgs + `${field} должен содержать минимум ${msg['arguments'] || 0 } символов! \n`
                        if(msg?.validation === 'max')
                            return msgs + `${field} должен быть не более ${msg['arguments'] || 0 } символов! \n`;
                        if(msg?.validation === 'spaces')
                            return msgs + `${field} должен быть без пробелов!`;
                        return msgs;
                    }, '');
                    setResult(setRegisterError, invalidPasswordMessage);
                } else {
                    setResult(setRegisterError, res['message']);
                }
            }
            setIsFetchingReg(false);
        } catch (err) {
            setResult(setRegisterError, err.message || err);
            setIsFetchingReg(false);
        }
    }
    return (
        <div className=" overflow-auto">
            <>
                {[changeSuccess, registerSuccess].filter(success => success).map(success =><Alert key={genUUID()} variant="success" className="text-lg">
                    {success}
                </Alert>)}
                {[changeError, registerError].filter(error => error).map(error =><Alert key={genUUID()} variant="danger" className="text-lg">
                    {error}
                </Alert>)}
            </>
            <InputGroup className="mb-3">
                <Form.Select aria-label="Choose role" value={newUserRole} onChange={handlerChangeRole}>
                    <option value="realtor">Риелтор</option>
                    <option value="admin">Админ</option>
                </Form.Select>
                <Form.Control placeholder={"Имя пользователя"} aria-label="Username" value={newUserUsername} onChange={e => setNewUserUsername(e.target.value)}/>
                <Form.Control placeholder={"Пароль"} aria-label="Password" value={newUserPassword} onChange={e => setNewUserPassword(e.target.value)}/>
                <Button disabled={isFetchingReg} variant="outline-secondary" onClick={handlerRegisterUser}>
                    {
                        isFetchingReg ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> :
                            'Добавить пользователя'
                    }
                </Button>
            </InputGroup>
            <Table onClick={ClickPassHandler} striped bordered hover className="overflow-auto" >
                <thead>
                <tr>
                    <th>#</th>
                    <th>Имя пользователя</th>
                    <th>Роль</th>
                    <th>Пароль</th>
                </tr>
                </thead>
                <tbody>
                {!errorFetching
                    && users.map((user, idx) => (
                        <>
                            <tr key={genUUID()}>
                                <td className="w-[10%]">{idx+1}</td>
                                <td className="w-[45%]">{user.username}</td>
                                <td className="w-[20%]">{user.role}</td>
                                <td data-user-pass={user.username} className="w-[25%]">
                                    {
                                        activeChngPass !== user.username
                                            ?
                                            user.password
                                            :
                                            <div className="w-full">
                                                 <InputGroup className="w-full change-pass">
                                                     <Button onClick={onChangePassClick} variant="outline-secondary" id="button-addon1">
                                                         {
                                                             isCngFetching ?
                                                                 <Spinner
                                                                     as="span"
                                                                     animation="border"
                                                                     size="sm"
                                                                     role="status"
                                                                     aria-hidden="true"
                                                                 /> :
                                                             'Изменить'
                                                         }
                                                     </Button>
                                                     <Form.Control
                                                         aria-label="Изменить пароль пользователя"
                                                         aria-describedby="basic-addon1"
                                                         ref={inputRef}
                                                         placeholder={user.password}
                                                     />
                                                 </InputGroup>
                                            </div>
                                    }
                                </td>
                            </tr>
                        </>
                    ))
                }
                </tbody>
            </Table>
            {errorFetching &&
                <Alert key="danger" variant="danger" className="text-lg">
                    Произошла ошибка при получении списка пользователей..
                </Alert>
            }
        </div>
    );
}

export default Users;