import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleIsFetching, setLoadSite } from "../../../redux/kalinkaSlice";
import {dbAPI} from "../../../api/api";
import {Navigate, Route, Routes} from "react-router-dom";
import Login from "../Login/Login";


const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const isAuthorized = useSelector(state => state.kalinka.isAuthorized);

    useEffect(() => {
        (async function loadSite() {
            dispatch(toggleIsFetching(true));
            try{
                dispatch(setLoadSite(await dbAPI.loadSite()));
            }catch {}
            dispatch(toggleIsFetching(false));
        })();
    }, []);

    return (
        <div className="text-center flex h-screen ">
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='*' element={isAuthorized ? children : <Navigate to="/login"/>}/>
            </Routes>
        </div>

    )
}
export default Layout;