import React from "react";
// import style from './Menu.module.css'


import { IoSettingsOutline } from 'react-icons/io5';
import { AiOutlineAlert } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { BsDownload } from 'react-icons/bs';

import Button from "../../../Button/Button";
import style from '../../../Button/Button.module.css';
// import { dbAPI } from "../../../../../api/api";

const Menu = () => {
    return (
        <div className="flex flex-col items-center">
            <Button text="Парсинг" title="Parsing">  <IoSettingsOutline size={22} /> </Button>
            <Button text="База данных" title="DataBase"> <FaUsers size={22} /> </Button>
            <Button text="Настройки" title="Settings" > <AiOutlineAlert size={22} /> </Button >
            <a className="mt-4" href={'api/db'} download={'kalinkaDB.json'} >
                <div className={style.btn}>
                    <BsDownload size={22} />
                    Скачать
                </div>
            </a>
        </div>
    )
}

export default Menu;