import React from "react";
import {useSelector} from "react-redux";
// import logo from "../../../../assets/img/logo.png"

const Logo = () => {
    const username = useSelector(state => state.kalinka.username) || '';

    return (
        <div className="pt-3 w-3/5">
            <h3 className="text-xl font-mono font-bold">Парсер недвижимости</h3>
            <h3 className="text-xl font-mono font-bold mt-5">{username}</h3>
        </div>

    )
}
export default Logo;