import React, {useState} from "react";
import {useDispatch} from "react-redux";
import styleDB from './Database.module.css';
import style from '../Page.module.css';
import ObjectItem from "./ObjectItem/ObjectItem";
import Preloader from '../../../Preloader/Preloader';
import {
    changeDbActiveTab,
    changePage,
    changeSelectShow,
    toggleIsFetching,
    setGlobalObj,
    changeActiveCatKey, removeCatKey
} from '../../../../../redux/kalinkaSlice';

import {useSelector} from 'react-redux';
import {genUUID, setResult} from "../../../../../utils/common";
import {dbAPI} from "../../../../../api/api";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import {Alert} from "react-bootstrap";

const Database = () => {
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.kalinka.currPage);
    let pagesCount = useSelector(state => state.kalinka.pageCount);
    const activeTab = useSelector(state => state.kalinka.databaseActiveTab);
    const kalinkaObjects = useSelector(state => state.kalinka.objectsToShow);
    const isFetching = useSelector(state => state.kalinka.isFetching);
    const selectShow = useSelector(state => state.kalinka.selectShow);
    const pageSize = useSelector(state => state.kalinka.pageSize);
    const catKeys = useSelector(state => state.kalinka.catKeys);
    const activeCatKeys = useSelector(state => state.kalinka.activeCatKey);

    const [isFetchingRm, setIsFetchingRm] = useState(false);
    const [successRm, setSuccessRm] = useState('');
    const [errorRm, setErrorRm] = useState('');

    const changeSelectHandler = async (e) => {
        const selected = e.target.value;
        if (activeTab === 'db') {
            dispatch(toggleIsFetching(true));
            dispatch(changeSelectShow(selected));
            dispatch(changePage(1));
            dispatch(setGlobalObj(await dbAPI.getObjects(1, pageSize, selected, activeCatKeys)))
            dispatch(toggleIsFetching(false));
        } else {
            dispatch(changeSelectShow(selected));
        }
    };
    const changeActiveCatKeyHandler = async (e) => {
        const activeCatKeys = e.target.value;
        dispatch(toggleIsFetching(true));
        dispatch(changeActiveCatKey(activeCatKeys));
        dispatch(changePage(1));
        dispatch(setGlobalObj(await dbAPI.getObjects(1, pageSize, selectShow, activeCatKeys)))
        dispatch(toggleIsFetching(false));
    };

    const tabClickHandler = async (e) => {
        const tab = e.target.dataset.tab;
        if (tab) {
            if (activeTab === 'curr' && tab !== activeTab) {
                dispatch(toggleIsFetching(true));
                dispatch(changePage(1));
                dispatch(setGlobalObj(await dbAPI.getObjects(1, pageSize, selectShow)));
                dispatch(changeDbActiveTab(tab));
                dispatch(toggleIsFetching(false));
            } else {
                dispatch(changeDbActiveTab(tab));
            }
        }
    }

    let pages = [];
    let lastPage = currentPage + 9 > pagesCount ? pagesCount + 1 : currentPage + 9;
    for (let i = currentPage - 1 ? currentPage - 1 : 1; i < lastPage; i++) {
        pages.push(i);
    }
    const removeCatHandler = async () => {
        try {
            setIsFetchingRm(true);
            const res = await dbAPI.removeCat(activeCatKeys)
            if (res['success']) {
                dispatch(removeCatKey(activeCatKeys));
                setResult(setSuccessRm, res['message']);
                dispatch(changePage(1));
                dispatch(toggleIsFetching(true));
                dispatch(setGlobalObj(await dbAPI.getObjects(1, pageSize, selectShow)));
                dispatch(toggleIsFetching(false));
            } else {
                setResult(setErrorRm, res['message']);
            }
            setIsFetchingRm(false);
        } catch (e) {
            setIsFetchingRm(false);
            setResult(setErrorRm, e.message || e);
        }

    }

    return (
        <div className="border flex h-full flex-col">
            <div className={style.block + " w-[100%] bg-[#18a4e1] text-white mb-[15px] "}>
                <div className={style.header}>
                    <div className="flex gap-x-3" onClick={tabClickHandler}>
                        <h3 className={"text-left text-xl font-mono text-gray-800 cursor-pointer ".concat(activeTab === "db" ? '' : 'text-[#415a7a]')}
                            data-tab="db">База данных</h3>
                        <h3 className={"text-left text-xl font-mono text-gray-800 cursor-pointer ".concat(activeTab === "curr" ? '' : 'text-[#415a7a]')}
                            data-tab="curr">Текущая сессия</h3>
                    </div>

                    <div className={styleDB.pagination}>
                        {
                            pages.map(p => {
                                return activeTab === 'curr' ?
                                    <span key={genUUID()} onClick={() => dispatch(changePage(p))}
                                          className={currentPage === p ? styleDB.selectedPage : ''}>{p} </span>
                                    : <span key={genUUID()} onClick={async () => {
                                        dispatch(changePage(p));
                                        dispatch(toggleIsFetching(true));
                                        try {
                                            dispatch(setGlobalObj(await dbAPI.getObjects(p, pageSize, selectShow)));
                                        } catch {
                                        }
                                        dispatch(toggleIsFetching(false));
                                    }
                                    } className={currentPage === p ? styleDB.selectedPage : ''}>{p} </span>

                            })
                        }
                    </div>
                    <div>
                        {catKeys.length && activeTab === 'db' ?
                            <Button variant="danger" onClick={removeCatHandler} className="rounded-3xl mr-3">
                                {isFetchingRm ? <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : "Удалить текущий раздел"}
                            </Button> : ''
                        }
                        {
                            catKeys.length && activeTab === 'db' ?
                                <select
                                    name="catKeys"
                                    value={activeCatKeys}
                                    className="rounded-xl bg-white text-gray-800 px-2 py-1 mr-3"
                                    onChange={changeActiveCatKeyHandler}
                                >
                                    <option value="all">{"Все разделы"} </option>
                                    {
                                        catKeys.map(cat => <option value={cat}>{cat} </option>)
                                    }
                                </select> : ''
                        }
                        <select
                            name="chart"
                            className="rounded-xl bg-white text-gray-800 px-2 py-1"
                            onChange={changeSelectHandler}
                            value={selectShow}
                        >
                            <option value="all">Все</option>
                            <option value="common">Обычные</option>
                            <option value="new">Новые</option>
                            <option value="deleted">Удаленные</option>
                            <option value="price">Цена</option>
                            <option value="finishing">Отделка</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={style.block + ' ' + styleDB.itemsBlock}>
                {
                    [errorRm].filter(error => error).map(error =>
                        <Alert key={genUUID()} variant="danger" className="text-lg">
                            {error}
                        </Alert>)
                }
                {
                    [successRm].filter(success => success).map(success =>
                        <Alert key={genUUID()} variant="success" className="text-lg">
                            {success}
                        </Alert>)
                }
                {
                    isFetching
                        ? <div className={"text-stone-400 text-[40px] flex items-center justify-center h-full"}>
                            <Preloader/>
                        </div>
                        : kalinkaObjects.length === 0
                            ? <div className={"text-stone-400 text-[40px] flex items-center justify-center h-full"}>
                                <span>Пусто...</span>
                            </div>
                            : kalinkaObjects.map((item, idx) =>
                                <ObjectItem
                                    key={genUUID()}
                                    title={item.title}
                                    salePriceRub={item.salePriceRub}
                                    imageSrc={item.previewImage}
                                    description={item.description.replaceAll('<br />', '\n')}
                                    area={item.area}
                                    rooms={item.rooms}
                                    bathrooms={item.bathrooms}
                                    bedrooms={item.bedrooms}
                                    lotid={item.lotid}
                                    finishing={item.finishing}
                                    url={item.url}
                                    itemType={item.itemType}
                                />
                            )

                }
            </div>
        </div>
    )
}
export default Database;