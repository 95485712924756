import {createSlice} from "@reduxjs/toolkit";

const kalinkaSlice = createSlice({
    name: 'kalinka',
    initialState: {
        objects: [],
        toShowObjects: [],
        dbObjects: [],
        dbPageCount: 0,
        dbCurrPage: 1,
        databaseActiveTab: 'db',
        toShowCountObjects: 0,
        isFetching: false,
        objectsCollected: 0,
        pageSize: 50,
        currentPage: 1,
        currPage: 1,
        pageCount: 1,

        objectsToShow: [],
        filters: {
            all_obj: true,
            price: false,
            newObjects: false,
            deletedObjects: false,
            finishing: false
        },
        selectShow: 'all',
        allSelectShowTypes: ['all', 'common', 'deleted', 'new', 'price', 'finishing'],
        isAuthorized: false,
        isFetchingLogin: false,
        username: undefined,
        telegram: [],
        catKeys: [],
        activeCatKey: 'all',
        shortPass: '',
        role: '',
        users: [],
        parseSchedule: []
    },
    reducers: {
        setLoadSite(state, action) {
            state.dbObjects = action.payload?.objectsData?.data || [];
            state.isAuthorized = action.payload?.isAuthorized || false;
            state.username = action.payload?.username || undefined;
            state.pageSize = action.payload?.pageSize || 50;
            state.role = action.payload?.role || 'realtor';
            state.dbPageCount = action.payload?.objectsData?.pageCount || 0;
            state.shortPass = action.payload?.shortPass || '';
            state.telegram[0] = action.payload?.shortToken || '';
            state.telegram[1] = action.payload?.chatID || '';
            state.catKeys = action.payload?.catKeys || [];
            const schedule = action.payload?.parseSchedule || '30 12 * * *';
            state.parseSchedule = schedule.split(' ').slice(0, 2);
            state.objectsToShow = state.dbObjects;
            state.pageCount = state.dbPageCount;

        },
        changeActiveCatKey(state, action) {
          state.activeCatKey = action.payload;
        },
        changeTelegramToken(state, action) {
            state.telegram[0] = action.payload?.shortToken || '';
            state.telegram[1] = action.payload?.chatID || '';
        },
        changeParseSchedule(state, action) {
            const schedule = action.payload || '30 12 * * *';
            state.parseSchedule = schedule.split(' ').slice(0, 2);
        },
        addCatKey(state, action) {
            state.catKeys.push(action.payload)
            state.catKeys = [...new Set(state.catKeys)];
        },
        removeCatKey(state, action) {
            if(action.payload === 'all') {
                state.catKeys = [];
            } else {
                state.catKeys = state.catKeys.filter(key => !(key === action.payload));
                state.activeCatKey = 'all';
            }
        },
        setGlobalObj(state, action) {
            state.dbObjects = action.payload.data;
            state.dbPageCount = action.payload.pageCount;
            state.objectsToShow = state.dbObjects;
            state.pageCount = state.dbPageCount;
        },
        addObject(state, action) {
            state.objects = action.payload;
            state.toShowObjects = state.objects;
            state.toShowCountObjects = state.toShowObjects.length;
            state.objectsCollected = state.objects.length;
        },
        addRegUser(state, action) {
            state.users.push({
                username: action.payload.username,
                role: action.payload.role,
                shortPass: action.payload.password
            });
        },
        toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        toggleIsFetchingLogin(state, action) {
            state.isFetchingLogin = action.payload;
        },
        changePage(state, action) {
            if (state.databaseActiveTab === 'curr') {
                state.currentPage = action.payload;
                state.pageCount = Math.ceil(state.toShowCountObjects / state.pageSize);
                state.objectsToShow = state.toShowObjects.slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);
            } else {
                state.dbCurrPage = action.payload;
            }
            state.currPage = action.payload;
        },
        changeCheckboxChecked(state, action) {
            state.filters[action.payload.key] = action.payload.checked;
        },
        changeSelectShow: (state, action) => {
            state.selectShow = state.allSelectShowTypes.includes(action.payload) ? action.payload : 'all';
            if (state.databaseActiveTab === 'curr') {
                state.toShowObjects = state.objects.filter(oneObj => state.selectShow === 'all' ? true : oneObj.itemType.includes(state.selectShow));
                state.objectsToShow = state.toShowObjects;
            } else {

            }

            state.toShowCountObjects = state.toShowObjects.length;
        },
        changeDbActiveTab(state, action) {
            state.databaseActiveTab = action.payload;
            if (state.databaseActiveTab === 'curr') {
                state.currPage = state.currentPage;
                state.pageCount = Math.ceil(state.toShowCountObjects / state.pageSize);
                state.objectsToShow = state.toShowObjects.slice((state.currPage - 1) * state.pageSize, state.currPage * state.pageSize);
            } else {
                state.currPage = state.dbCurrPage
                state.pageCount = state.dbPageCount;
                state.objectsToShow = state.dbObjects;
            }
        },
        changePassword(state, action) {
            const {username, newPass} = action.payload;
            state.users = state.users.map(user => user.username === username ? {...user, password: newPass} : user);
        },
        changeUserPass(state, action) {
            const {newPass} = action.payload;
            state.shortPass = newPass;
        },
        logout(state, action) {
            state.isAuthorized = false;
            state.objects = [];
            state.toShowObjects = [];
            state.dbObjects = [];
            state.objectsToShow = [];
            state.dbPageCount = 0;
            state.dbCurrPage = 1;
            state.toShowCountObjects = 0;
            state.objectsCollected = 0;
            state.currentPage = 1;
            state.currPage = 1;
            state.pageCount = 1;
        },
        setUsers(state, action) {
            state.users = action.payload || [];
        }
    }
});


export const {
    addObject,
    toggleIsFetching,
    changePage,
    changeCheckboxChecked,
    changeActiveCatKey,
    changeSelectShow,
    setGlobalObj,
    changeDbActiveTab,
    setLoadSite,
    toggleIsFetchingLogin,
    logout,
    setUsers,
    changePassword,
    changeUserPass,
    changeParseSchedule,
    addRegUser,
    changeTelegramToken,
    addCatKey,
    removeCatKey

} = kalinkaSlice.actions;

export default kalinkaSlice.reducer;