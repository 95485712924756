import axios from "axios";

// axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'http://localhost:5000/';

export const kalinkaAPI = {
    getPage(url) {
        return axios.get(url).then(resp => resp.data);
    }
}
export const dbAPI = {
    loadSite() {
        return axios.get('/api/load-site', {
            withCredentials: true
        }).then(resp => resp.data);
    },
    getObjects(page, limit = 100, types = 'all', catKey='all') {
        return axios.get(`api/objects?page=${page}&limit=${limit}&types=${types}&catKey=${catKey}`, {
            withCredentials: true
        }).then(resp => resp.data);
    },
    uploadObjects(parametrs) {
        const {data, types, catKey, url, parseDays, parseHours} = parametrs;
        return axios.post('api/objects', {
            data,
            types,
            catKey,
            url,
            parseDays,
            parseHours
        }, {
            withCredentials: true
        }).then(resp => resp.data.data);
    },
    removeCat(activeCatKeys) {
        return axios.post('api/removeCat', {
            catKey: activeCatKeys
        }, {
            withCredentials: true
        }).then(resp => resp.data);
    },
    setScheduleCheck(schedule) {
        return axios.post('api/schedule_check', {
            schedule
        }, {
            withCredentials: true
        }).then(resp => resp.data);
    },
    getUsers() {
        return axios.get('api/users', {
            withCredentials: true
        }).then(resp => resp.data)
    },
    changeTgToken(BOT_TOKEN, CHAT_ID) {
      return axios.post('api/tgToken', {
          BOT_TOKEN,
          CHAT_ID
      }, {
          withCredentials: true
      }).then(resp => resp.data);
    },
    checkTgToken(BOT_TOKEN) {
        return axios.get(`https://api.telegram.org/bot${BOT_TOKEN}/getUpdates`, {
            accept: 'application/json',
        }).then(resp => resp.data);
    },
    login(login, password) {
        return axios.post('api/login', {
            login,
            password
        }, {
            withCredentials: true
        }).then(resp => resp.data);
    },
    register(login, password, role) {
        return axios.post('api/register', {
            login,
            password,
            role
        }, {
            withCredentials: true
        }).then(resp => resp.data);
    },
    changePass(login, newPass) {
        return axios.post('api/changePassword', {
            login,
            newPass
        }, {
            withCredentials: true
        }).then(resp => resp.data);
    },
    changeUserPass(oldPass, newPass) {
        return axios.post('api/userChangePass', {
            oldPass,
            newPass
        }, {
            withCredentials: true
        }).then(resp => resp.data);
    },
    logout() {
        return axios.get('api/logout', {
            withCredentials: true
        }).then(resp => resp.data);
    }
}