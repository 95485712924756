import React from "react";
import style from './ObjectItem.module.css';
import { BiRuble } from 'react-icons/bi';
import { MdOutlineBedroomParent, MdOutlineBathroom, MdOutlineMeetingRoom } from 'react-icons/md';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import { FaPaintRoller } from 'react-icons/fa';

import { BiArea } from 'react-icons/bi';

const ObjectItem = ({ title, imageSrc, salePriceRub, description, itemType, area, rooms, bathrooms, bedrooms, lotid, finishing, url }) => {
    const getItemType = () => {
        if (itemType === 'deleted') return style.deletedItem
        else if(itemType === 'new') return style.newItem
        else return style.commonItem  
    }

    return (
        <div className={style.objectItem + ' ' + getItemType()}>
            <div className={style.imageBlock}>
                <img className="h-[100%] rounded-2xl" src={imageSrc} alt="" />
                <div className={style.priceBlock}>
                    <div className="flex items-center justify-center">
                        <span className={style.priceText}>{salePriceRub}</span>
                        <BiRuble size={20} />
                    </div>
                    

                </div>
                
            </div>
            <div className={style.mainInfoBlock}>
                <div className={style.blueBG}>
                    <a href={url} target="_blank">{title}</a>
                </div>
                <div className={style.descriptionBlock + ' ' + style.blueBG }>
                    {description}
                </div>
                <div className={style.blueBG + ' flex justify-center'}>
                    <div className={style.iconsValue}>
                        <FaPaintRoller size={15} />
                        <span className="ml-2">{finishing}</span>
                    </div>
                </div>
            </div>
            <div >
                
                <div className={style.iconsValue}>
                    <AiOutlineFieldNumber size={30} />
                    <span className={style.value}>{lotid}</span>
                </div>
                <div className={style.iconsValue}>
                    <MdOutlineBedroomParent size={30} />
                    <span className={style.value}>{bedrooms}</span>
                </div>
                <div className={style.iconsValue}>
                    <MdOutlineBathroom size={30} />
                    <span className={style.value}>{bathrooms}</span>
                </div>
                <div className={style.iconsValue}>
                    <MdOutlineMeetingRoom size={30} />
                    <span className={style.value}>{rooms}</span>
                </div>
                <div className={style.iconsValue}>
                    <BiArea size={30} />
                    <span className={style.value}>{area}</span>
                </div>
                
                
                
            </div>
        </div>
    );
}

export default ObjectItem;

// address: "Россия, Московская область, Одинцовский район, деревня Сколково"
// area: 600
// areaLand: 25
// bathrooms: 4
// bedrooms: 4
// cabinet: true
// description: "Готовый для проживания дом 600 кв.м на участке 25 соток в коттеджном поселке в Сколково.<br />\n<br />\nПланировка дома:<br />\nЦоколь: зал, бильярд, турецкая баня, душевая, с/у, постирочная;<br />\n1 этаж: прихожая, гардеробная, холл, с/у, кухня-столовая, гостиная, спальня, с/у, терраса;<br />\n2 этаж: 3 спальни, 2 с/у, гардеробная;<br />\nМансарда: проходная комната, помещение свободного назначения, кладовые. <br />\n<br />\nНа территории расположены- открытый бассейн, зона барбекю, навес для 2 машин, гараж и домик для охраны."
// previewImage: "https://pl.kalinka-realty.ru/media/cache/resolve/default_middle/default/0026/59/5ae287e2f6f38178a40fb62de4d503ea90a038b9.jpg"
// rooms: 0
// salePriceEur: 4932711.736816
// salePriceRub: 298800000
// salePriceUsd: 4949666.79201
// title: "Сколково"