import React from "react";
import Logo from "./Logo"
import {IoExitOutline} from 'react-icons/io5'
import Menu from "./Menu/Menu"
import {dbAPI} from "../../../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import {logout} from "../../../../redux/kalinkaSlice";

const Sidebar = () => {
    const dispatch = useDispatch();
    const isAuthorized = useSelector(state => state.kalinka.isAuthorized);
    const logoutClickHandler = async () => {
        try {
            const resp = await dbAPI.logout();
            if (resp.success) {
                dispatch(logout());
            }
            throw resp.message;
        } catch (e) {
            console.log(e);
        }

    }
    return (
        <>
            {
                !isAuthorized
                    ? <Navigate to={"/login"}/>
                    : <nav className="w-1/6 min-w-[16.66%] p-3 flex flex-col justify-between items-center">
                        <Logo/>
                        <Menu/>
                        <div className="p-5 self-start flex items-center gap-1">
                            <IoExitOutline size={20} className="mt-[3px]"/>
                            <button onClick={logoutClickHandler}>Выход</button>
                        </div>
                    </nav>
            }
        </>
    );
}
export default Sidebar;